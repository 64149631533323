import React from 'react'
import { Text } from 'rebass'
import Stripe from '../Stripe'
import MerchandiseListing from '../MerchandiseListing'
import { List, FlexWrapper, ReleativeBox } from './styles'
import { map } from 'lodash'
const Merchandise = (props) => {
  const title = Object.keys(props.artist)
    .map((key, index) => {
      const listing = <List ml={[0, null, '7vw']} pt={[0, null, 50]} mb={[0, null, 10]} key={`${key}:${index}`}>
        {
          map(props.artist[key], (artist, key) => {
            return <MerchandiseListing items={artist} key={`${key}:${index}:${artist}`} />
          })
        }
      </List>
      return <>
        <Text
          is='h2' fontSize={[8, null, 9]} fontWeight='bold' className='sans' color='white'
          mb={[0, null, 50]} mt={[-10, null, 10]} key={`mlt:{props.artist[key]}`}
        >
          {key}
        </Text>
        {listing}
      </>
    })
  return (
    <ReleativeBox w={1 / 1} pt={[0, 35]} pl={[0, null, '6vw']} pb={[20, null, 0]}>
      <Stripe heading />
      <Stripe bottom />
      <FlexWrapper flexWrap='wrap' w='100%' px='5%' pt={[20, null, 55]} pb={[30, 25, 85]}>
        {
          title
        }
      </FlexWrapper>
    </ReleativeBox>
  )
}

export default Merchandise
