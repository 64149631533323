import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { isWindowAvailable } from '../util'
import { black } from '../theme'
import Layout from '../components/Layout'
import Router from '../components/Router'
import Merchandise from '../components/Merchandise'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import PageTitle from '../components/PageTitle'
import { first, map, concat, groupBy, forEach, flatten } from 'lodash'

class MerchListingTemplate extends Component {
  constructor (props) {
    super(props)
    const merged = concat(props.data.releases.edges, props.data.merchandise.edges)
    this.state = { merch: this.normalizedListing({ listing: merged }) }
  }

  normalizedListing ({ listing }) {
    const normalizedListing = {}
    const merchByArtist = groupBy(listing, (release) => {
      return (release.node.acf.release_artist) ? first(release.node.acf.release_artist).post_title : release.node.title
    })
    forEach(merchByArtist, (artist, key) => {
      normalizedListing[key] = map(artist, merch => {
        const isRelease = (merch.node.acf.merchandise)
        if (!isRelease) {
          return merch.node.acf.release_products.map((release) => {
            const image = merch.node.acf.release_artwork
            if (image) {
              const sizes = (image && image.localFile) ? image.localFile.childImageSharp.sizes : null
              release.sizes = sizes
              release.meta = `${first(merch.node.acf.release_artist).post_title} - ${merch.node.title}`
            }
            return release
          })
        } else {
          return merch.node.acf.merchandise.map((merchandise) => {
            if (merchandise.image) {
              merchandise.sizes = merchandise.image.localFile.childImageSharp.sizes
            }
            merchandise.meta = `${first(merchByArtist[key]).node.title}`
            return merchandise
          })
        }
      })
      normalizedListing[key] = [flatten(normalizedListing[key])]
    })
    return normalizedListing
  }

  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.router.edges).node.items}
        />
        <PageTitle title={currentPage.title} prefix />
        <Merchandise artist={this.state.merch} />

        <Stripe top />
        <Footer />
      </Layout>
    )
  }
}

export default MerchListingTemplate

export const MerchListingQuery = graphql`
  query merchListingPageQuery($id: String!) {
    router:allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    merchandise:allWordpressPage(filter: {acf: {merchandise: {elemMatch: {name: {ne: null}}}}}) {
  
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            release_artist {
              post_title
              post_name
            }
            release_id
            release_buy_link
            release_artwork {
              localFile {
                childImageSharp {
                  sizes(
                    maxWidth: 800,
                    maxHeight: 800
                  ) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                }
              }
            }
            release_products {
              name
              price
              weight
              download_guid
            }
            merchandise {
              name
              price
              weight
              depth
              image {
                localFile {
                  childImageSharp {
                    sizes(
                      maxWidth: 800,
                      maxHeight: 800
                    ) {
                        ...GatsbyImageSharpSizes_withWebp
                      }
                  }
                }
              }
            }
          }
        }
      }
    }
    releases:allWordpressPage(filter: {acf: {release_products: {elemMatch: {name: {ne: null}}}}}) {
    
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            release_artist {
              post_title
              post_name
            }
            release_id
            release_buy_link
            release_artwork {
              localFile {
                childImageSharp {
                  sizes(
                    maxWidth: 800,
                    maxHeight: 800
                  ) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                }
              }
            }
            release_products {
              name
              price
              weight
              download_guid
            }
            merchandise {
              name
              price
              weight
              depth
            }
          }
        }
      }
    }
    
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        seo_page_title
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
