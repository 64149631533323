import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { isWindowAvailable } from '../../util'
import { List, CartButton, MerchandiseItem, Price, Shadow } from './styles'
import Img from 'gatsby-image'
function getLocation () {
  return isWindowAvailable ? window.location.href : ''
}
function getPriceString (price) {
  price = price.toString()
  return price.indexOf('.00') ? price : `${price}.00`
}
const MerchandiseListing = (props) => {
  return (
    <List ml={[0, null, '4vw', 0]} mt={[40, null, -40]} line={props.line}>
      {

        props.items.map((item, key) => {
          const cleanedTitle = item.name.replace('&#038;', '&').replace('&#8211;', '&')
          const uid = `${item.name}:${key}`
          const title = <Text fontSize={[1, null, 0]} pr={['25%', null, 0]}>{ cleanedTitle }</Text>
          const itemTitle = (item.meta) ? `${item.meta} - ${cleanedTitle}` : cleanedTitle
          const snipcartId = `sc:${item.name}:${itemTitle}`
          return (
            <MerchandiseItem
              width={[1 / 1, 1 / 2, 1 / 3]} mb={[50, null, 20]} pr={[0, '8%', '2%']}
              key={`${uid}:`}
              className='snipcart-add-item'
              data-item-id={snipcartId}
              data-item-name={itemTitle}
              data-item-price={getPriceString(item.price)}
              data-item-weight={item.weight}
              data-item-url={getLocation()}
              data-item-image={item.sizes.src}
              data-item-file-guid={item.download_guid}
            >
              <Flex flexWrap='wrap'>
                <Box width={[1 / 1, null, 1 / 3]} mb={15}>
                  <Shadow className='shadow'>
                    {item.sizes &&
                      <Img
                        src={item.sizes.src}
                        sizes={item.sizes}
                        alt={item.alt}
                      />
                    }
                  </Shadow>
                </Box>
                <Box width={[1 / 1, null, 3 / 5]} pl={[0, null, 4]} mb={20}>
                  { item.name && title }
                  { item.price &&
                    <Price fontSize={[1, null, 0]}>
                    £{item.price} GBP
                    </Price>
                  }
                  <CartButton
                    className='snipcart-add-item'
                    data-item-id={snipcartId}
                    data-item-name={itemTitle}
                    data-item-price={getPriceString(item.price)}
                    data-item-weight={item.weight}
                    data-item-url={getLocation()}
                    data-item-image={item.sizes.base64}
                  >
                  Add to cart
                  </CartButton>
                </Box>
              </Flex>

            </MerchandiseItem>
          )
        })
      }
    </List>
  )
}

export default MerchandiseListing
