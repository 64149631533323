import styled from 'styled-components'
import { Box, Flex } from 'rebass'
import { media, borderHighlightColour } from '../../theme/'

export const FlexWrapper = styled(Flex)`
  position: relative;
  z-index: 10;
`
export const List = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  ${media.LargeScreenUp`
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -15%;
      left: 0;
      transform: translateX(-3.7vw);
      width: 4px;
      height: 110%;
      background: ${borderHighlightColour};
      z-index: -2;
    }
  `}

  ${media.GiantScreenUp`
    &:after {
      transform: translateX(-6.64vw);
    }
  `}
`
export const ReleativeBox = styled(Box)`
  position: relative;
`
