import styled from 'styled-components'
import { Text, Box, Flex } from 'rebass'
import { darken } from 'polished'
import { media, red, ctaButtonColor, priceColour, easeOutExpo } from '../../theme/'

export const List = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  color: white;
`

export const ReleativeBox = styled(Box)`
  position: relative;
`

export const MerchandiseItem = styled(Box)`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  ${media.LargeScreenUp`
    margin-right: 0;
  `}
  /* .gatsby-image-wrapper {
    min-width: 100px;
  } */
  &:hover .shadow:after {
    opacity: 0.5;
    will-change: opacity;
  }
  &:hover a {
    background-color: ${darken(0.13, red.mid)};
  }
`

export const CartButton = styled.a`
  background-color: ${ctaButtonColor};
  display: inline-block;
  padding: 2px 12px;
  margin-top: 20px;
  color: white;
  text-align: center;
  white-space: nowrap;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  transition: background-color 0.3s ${easeOutExpo};
  &:hover {
    background-color: ${darken(0.13, red.mid)};
  }
`

export const Price = styled(Text)`
  color: ${priceColour};
`

export const Shadow = styled(Box)`
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 95%;
    opacity: 0.6;
    box-shadow: 0 25px 25px 0px rgba(0,0,0,0.52), 0 20px 20px 0 rgba(0,0,0,0.48);
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(168, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ${easeOutExpo};
  }
  &:hover:after {
    opacity: 0.5;
    will-change: opacity;
  }
`
